import { sortBy } from 'lodash';
import { Loading } from 'ra-ui-materialui';
import { Fragment, useMemo } from 'react';
import { useTranslate } from 'react-admin';

import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useStaffUserSummary } from './hooks';
import { filterPanelProps } from '../common/dataGrid/styles';

export const Summary = () => {
  const translate = useTranslate();
  const { summary, isPending } = useStaffUserSummary();

  const columnsSummary = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'count',
        headerName: translate('contentTranslation.fields.count'),
        width: 200,
      },
      {
        field: 'timeSpent',
        headerName: translate('contentTranslation.fields.timeSpent'),
        width: 200,
      },
      {
        field: 'meanTimeSpent',
        headerName: translate('contentTranslation.fields.meanTimeSpent'),
        width: 200,
      },
    ],
    [translate],
  );

  if (!summary) {
    return <Loading />;
  }

  return (
    <Stack gap={2}>
      {sortBy(Object.entries(summary), ([key]) => key)
        .reverse()
        .map(([key, value]) => (
          <Fragment key={key}>
            <Typography
              key={key}
              variant="h6"
              sx={{ fontWeight: 'bold', color: 'primary' }}
            >
              {translate(`locale.months.number.${key.slice(4)}`)}{' '}
              {key.slice(0, 4)}
            </Typography>
            {Object.entries(value).map(([_key, _value]) => {
              return (
                <Stack key={_key} gap={1}>
                  <Typography variant="body2">
                    {translate(`admin.contentTranslation.contentType.${_key}`)}
                  </Typography>
                  <DataGrid
                    rows={[_value]}
                    columns={columnsSummary}
                    loading={isPending}
                    density="compact"
                    disableRowSelectionOnClick
                    keepNonExistentRowsSelected
                    ignoreDiacritics
                    slotProps={{ filterPanel: filterPanelProps }}
                    getRowId={(row) => JSON.stringify(row)}
                    hideFooter
                  />
                </Stack>
              );
            })}
          </Fragment>
        ))}
    </Stack>
  );
};
