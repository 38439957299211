import zip from 'lodash/zip';
import React, { useCallback, useEffect, useRef } from 'react';
import { Loading } from 'react-admin';
import { FlatList, StyleSheet, Text, View } from 'react-native';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import { SuggestionContentType } from '@components/discussions/chat/contentSuggestion/types';
import { GuideRenderer } from '@components/GuideRenderer';
import { guideTheme } from '@components/GuideRenderer/resources/guideTheme';
import { useHandleLongPress } from '@hooks/useHandleLongPress';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import {
  logContentSuggestionView,
  logContentView,
} from '../../analytics/events';
import { useGuideItem } from '../../hooks/cms';
import { UserLocale } from '../../pages/common/contentTranslation/types';
import { colorTokens } from '../../themes';
import { Guide } from '../../types';
import { SendIcon } from '../discussions/SendIcon';

export const WIDTH = 148;
export const HEIGHT = 96;

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  '&:first-of-type': {
    padding: 0,
  },
  backgroundColor: 'paper.background',
});

interface GuideCarouselProps {
  slug?: string;
  item?: Guide;
  translatedItem?: Guide;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locale?: UserLocale;
}

const guideWidth = 390;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: guideWidth,
  },
  rendererWrapper: {
    height: 780,
  },
});

const borderWidth = 5;

const mobileStyles = StyleSheet.create({
  mobileContainer: {
    alignItems: 'center',
    width: guideWidth + 2 * borderWidth,
    height: 800,
    borderRadius: 50,
    borderColor: 'black',
    borderWidth,
    borderStyle: 'solid',
    overflow: 'hidden',
    alignSelf: 'center',
    margin: 5,
    display: 'flex',
  },
});

const flatListViewabilityConfig = { itemVisiblePercentThreshold: 90 };

const RenderAuthors = ({ item }: { item: Guide }) => {
  if (!item?.authorNames?.length) {
    return null;
  }
  return zip(item.authorNames, item.authorImages).map(([name, image]) => (
    <Box
      key={name}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '0.5rem',
      }}
    >
      <Avatar
        src={image}
        alt={name}
        sx={{
          width: 24,
          height: 24,
          borderWidth: 1,
          borderColor: 'background.grey',
          marginRight: 1,
        }}
      />
      <Typography variant="body2">{name}</Typography>
    </Box>
  ));
};

export const GuideCarousel = ({
  slug,
  item: guideItem,
  translatedItem,
  open,
  setOpen,
  locale,
}: GuideCarouselProps) => {
  // There is no case where we have only slugs for both content. We either have a slug and nothing else, an item, or an item and its translation counterpart
  const { data, isLoading } = useGuideItem(slug, locale, !guideItem);

  const itm = guideItem ?? data;
  const flatListRef = useRef<FlatList>(null);
  const flatListTranslatedRef = useRef<FlatList>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const pages = React.useMemo(
    () => GuideRenderer.splitPages(itm?.guideContent),
    [itm?.guideContent],
  );
  const translatedPages = React.useMemo(
    () => GuideRenderer.splitPages(translatedItem?.guideContent),
    [translatedItem?.guideContent],
  );
  const themeType = itm?.theme ?? 'theme1';

  const RenderItem = React.useCallback(
    ({ item, index }: { item: string; index: number }) => {
      return (
        <View style={styles.rendererWrapper}>
          <GuideRenderer.Memo
            content={item}
            themeType={themeType}
            pageNumber={index + 1}
            pagesCount={pages.length}
            width={guideWidth}
          />
        </View>
      );
    },
    [pages.length, themeType],
  );

  const handlePrev = useCallback(() => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      flatListRef.current?.scrollToIndex({ index: newIndex, animated: true });
      flatListTranslatedRef.current?.scrollToIndex({
        index: newIndex,
        animated: true,
      });
    }
  }, [currentIndex]);

  const handleNext = useCallback(() => {
    if (currentIndex < pages.length - 1) {
      const newIndex = currentIndex + 1;
      flatListRef.current?.scrollToIndex({ index: newIndex, animated: true });
      flatListTranslatedRef.current?.scrollToIndex({
        index: newIndex,
        animated: true,
      });
    }
  }, [currentIndex, pages.length]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (open) {
        if (event.key === 'ArrowRight') {
          handleNext();
        }
        if (event.key === 'ArrowLeft') {
          handlePrev();
        }
      }
    },
    [open, handleNext, handlePrev],
  );

  useEffect(() => {
    open && document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [open, handleKeyPress]);

  const onViewableItemsChanged = useCallback(
    ({ viewableItems }) => {
      if (viewableItems.length) {
        setCurrentIndex(viewableItems[0].index);
      }
    },
    [setCurrentIndex],
  );

  const getItemLayout = useCallback(
    (_, index: number) => ({
      length: guideWidth,
      offset: guideWidth * index,
      index,
    }),
    [],
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={translatedItem ? 'xl' : 'md'}
    >
      <StyledDialogContent>
        {isLoading && !guideItem ? (
          <Loading />
        ) : (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => handlePrev()}
              disabled={currentIndex === 0}
            >
              <ArrowBackIos />
            </IconButton>
            {translatedItem ? (
              <Stack
                direction="row"
                justifyContent={'space-between'}
                gap={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack direction="column" sx={{ flex: 1 }}>
                  <RenderAuthors item={itm} />
                  <View style={mobileStyles.mobileContainer}>
                    <FlatList
                      style={[
                        styles.container,
                        {
                          backgroundColor: pages?.length
                            ? guideTheme[themeType].background
                            : undefined,
                        },
                      ]}
                      ref={flatListRef}
                      data={pages}
                      renderItem={RenderItem}
                      horizontal
                      pagingEnabled
                      snapToInterval={guideWidth}
                      decelerationRate="fast"
                      showsHorizontalScrollIndicator={false}
                      getItemLayout={getItemLayout}
                      onViewableItemsChanged={onViewableItemsChanged}
                      viewabilityConfig={flatListViewabilityConfig}
                      ListEmptyComponent={
                        <Text style={{ padding: 50 }}>Empty</Text>
                      }
                    />
                  </View>
                </Stack>
                <Stack direction="column" sx={{ flex: 1 }}>
                  <RenderAuthors item={translatedItem} />
                  <View style={mobileStyles.mobileContainer}>
                    <FlatList
                      style={[
                        styles.container,
                        {
                          backgroundColor: translatedPages?.length
                            ? guideTheme[themeType].background
                            : undefined,
                        },
                      ]}
                      ref={flatListTranslatedRef}
                      data={translatedPages}
                      renderItem={RenderItem}
                      horizontal
                      pagingEnabled
                      snapToInterval={guideWidth}
                      decelerationRate="fast"
                      showsHorizontalScrollIndicator={false}
                      getItemLayout={getItemLayout}
                      onViewableItemsChanged={onViewableItemsChanged}
                      viewabilityConfig={flatListViewabilityConfig}
                      ListEmptyComponent={
                        <Text style={{ padding: 50 }}>Empty</Text>
                      }
                    />
                  </View>
                </Stack>
              </Stack>
            ) : (
              <Stack direction="column" sx={{ flex: 1 }}>
                <RenderAuthors item={itm} />
                <View style={mobileStyles.mobileContainer}>
                  <FlatList
                    style={[
                      styles.container,
                      {
                        backgroundColor: pages?.length
                          ? guideTheme[themeType].background
                          : undefined,
                      },
                    ]}
                    ref={flatListRef}
                    data={pages}
                    renderItem={RenderItem}
                    horizontal
                    pagingEnabled
                    snapToInterval={guideWidth}
                    decelerationRate="fast"
                    showsHorizontalScrollIndicator={false}
                    getItemLayout={getItemLayout}
                    onViewableItemsChanged={onViewableItemsChanged}
                    viewabilityConfig={flatListViewabilityConfig}
                    ListEmptyComponent={
                      <Text style={{ padding: 50 }}>Empty</Text>
                    }
                  />
                </View>
              </Stack>
            )}

            <IconButton
              onClick={() => handleNext()}
              disabled={currentIndex === pages.length - 1}
            >
              <ArrowForwardIos />
            </IconButton>
          </View>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

const getBackgroundForTheme = (theme: 'theme1' | 'theme2' | 'theme3') => {
  switch (theme) {
    case 'theme1':
      return colorTokens.surface.accentSeaContrast;
    case 'theme2':
      return colorTokens.surface.accentMandarinContrast;
    case 'theme3':
      return colorTokens.surface.accentLilacContrast;
  }
};

const getPointBackgroundForTheme = (theme: 'theme1' | 'theme2' | 'theme3') => {
  switch (theme) {
    case 'theme1':
      return colorTokens.surface.accentSea;
    case 'theme2':
      return colorTokens.surface.accentMandarin;
    case 'theme3':
      return colorTokens.surface.accentLilac;
  }
};

export const GuideItem = ({
  item,
  onSend,
  onLongPress,
  fromSuggestion = false,
  subjectId,
}: {
  item: Guide;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
    fromSuggestion?: boolean,
    subjectId?: number,
  ) => void;
  onLongPress?: () => void;
  fromSuggestion?: boolean;
  subjectId?: number;
}) => {
  const { title } = item;
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    if (fromSuggestion) {
      logContentSuggestionView(
        subjectId,
        SuggestionContentType.GUIDES,
        item.id,
      );
    } else {
      logContentView('guide', item.id);
    }
    setOpen(true);
  };

  const itemInfo = {
    type: DiscussionEventType.GUIDE,
    content: item.slug,
  };
  const [handlePress, startClick] = useHandleLongPress(onClick, onLongPress);
  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      elevation={0}
      sx={{
        backgroundColor: getBackgroundForTheme(item.theme),
      }}
    >
      {open ? (
        <GuideCarousel
          open={open}
          setOpen={setOpen}
          slug={item.slug}
          locale={item.locale}
        />
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: -0.1 * WIDTH,
            bottom: -0.4 * HEIGHT,
            borderRadius: '50%',
            width: `${0.75 * WIDTH}px`,
            height: `${0.75 * WIDTH}px`,
            backgroundColor: getPointBackgroundForTheme(item.theme),
          }}
        />
        <CMSCardActionArea
          onClick={handlePress}
          onMouseDown={startClick}
          height={HEIGHT}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          {item.sent && <CMSCardSentOverlay />}
          <CMSCardContent
            sx={{
              color: 'text.white',
            }}
          >
            <CMSCardTitle
              variant="subtitle2"
              component="h1"
              clamp={4}
              sx={{
                color: 'primary.inverse',
                fontFamily: 'Lora, serif',
                fontWeight: 'bold',
                textShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
              }}
            >
              {title}
            </CMSCardTitle>
            {item.sent && (
              <DownloadDoneIcon
                sx={(theme) => ({
                  position: 'absolute',
                  left: theme.spacing(1),
                  bottom: theme.spacing(0),
                })}
              />
            )}
          </CMSCardContent>
        </CMSCardActionArea>
      </Box>
      {onSend ? (
        <CardActions sx={{ padding: 0, position: 'relative', zIndex: 200 }}>
          <SendIcon
            onClick={() =>
              onSend(
                itemInfo,
                DiscussionEventType.GUIDE,
                item.id,
                fromSuggestion,
                subjectId,
              )
            }
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};

export default GuideItem;
