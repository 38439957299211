import React from 'react';
import { useTranslate } from 'react-admin';

import { AgeLevel, Categories } from '@boTypes/cms';
import { AgeLevelsList } from '@components/AgeLevel/AgeLevelList';
import { PregnancyFilter } from '@components/AgeLevel/PregnancyFilter';
import { CategoriesList } from '@components/Categories/CategoriesList';
import { Close, SearchRounded, Tune } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ContentType } from './contentTextList';

export const drawerWidth = 280;

export const Header = styled(Box)({
  marginLeft: '1rem',
  marginRight: '1rem',
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'flex',
  width: 'calc(100% - 2rem)',
  gap: '0.5rem',
});

export const Main = styled('main')({
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

export enum libraryMode {
  SEARCH = 'search',
  EXPLORER = 'explorer',
}

export const LocaleSelector = ({
  locale,
  setLocale,
}: {
  locale: string;
  setLocale: (event: any) => void;
}) => {
  const translate = useTranslate();
  return (
    <Box>
      <FormControl sx={{ minWidth: '30px' }}>
        <InputLabel id="locale-label" sx={{ marginTop: -1.5 }}>
          {translate('common.country')}
        </InputLabel>
        <Select
          variant="outlined"
          labelId="locale-label"
          label={translate('common.country')}
          id="locale-select"
          value={locale}
          onChange={(event) => setLocale(event.target.value)}
        >
          {['en', 'es', 'fr'].map((l) => (
            <MenuItem key={l} value={l}>
              {l}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const ModeSelector = ({
  mode,
  onModeChange,
}: {
  mode: libraryMode;
  onModeChange: (event: SelectChangeEvent) => void;
}) => {
  const translate = useTranslate();
  return (
    <Box>
      <FormControl sx={{ minWidth: '150px' }}>
        <InputLabel id="mode-label" sx={{ marginTop: -1.5 }}>
          {translate('library.mode')}
        </InputLabel>
        <Select
          variant="outlined"
          labelId="mode-label"
          label={translate('library.mode')}
          id="mode-select"
          value={mode}
          onChange={onModeChange}
        >
          <MenuItem value={libraryMode.SEARCH}>
            {translate('library.search')}
          </MenuItem>
          <MenuItem value={libraryMode.EXPLORER}>
            {translate('library.explorer')}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export const ContentTypeSelector = ({ selectedContentType, onTypeChange }) => {
  const translate = useTranslate();

  return (
    <Box sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
      <FormControl sx={{ minWidth: '150px' }}>
        <InputLabel id="type-label" sx={{ marginTop: -1.5 }}>
          {translate('library.contentType')}
        </InputLabel>
        <Select
          variant="outlined"
          labelId="type-label"
          label={translate('library.contentType')}
          id="type-select"
          value={selectedContentType}
          onChange={onTypeChange}
        >
          <MenuItem value={ContentType.MACRO}>
            {translate('library.macros')}
          </MenuItem>
          <MenuItem value={ContentType.POSTS}>
            {translate('library.posts')}
          </MenuItem>
          <MenuItem value={ContentType.GUIDES}>
            {translate('library.guides')}
          </MenuItem>
          <MenuItem value={ContentType.MASTERCLASSES}>
            {translate('library.masterclasses')}
          </MenuItem>
          <MenuItem value={ContentType.FOLDER}>
            {translate('library.folders')}
          </MenuItem>
          <MenuItem value={ContentType.PREGNANCY_QUESTIONS}>
            {translate('library.pregnancyQuestions')}
          </MenuItem>
          <MenuItem value={ContentType.ITEM_SUGGESTIONS}>
            {translate('library.itemSuggestions')}
          </MenuItem>
          <MenuItem value={ContentType.DAILY_TIPS}>
            {translate('library.dailyTips')}
          </MenuItem>
          <MenuItem value={ContentType.WEEKLY_TIPS}>
            {translate('library.weeklyTips')}
          </MenuItem>
          <MenuItem value={ContentType.FERTI_TIPS}>
            {translate('library.fertiTips')}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export const SearchField = ({
  search,
  resetSearch,
  onSearchChange,
  isLoading,
}: {
  search: string;
  resetSearch: () => void;
  onSearchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}) => {
  const translate = useTranslate();

  return (
    <TextField
      id="search-input"
      autoFocus
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {isLoading ? <CircularProgress size={24} /> : <SearchRounded />}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={resetSearch} edge="end">
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={translate('library.searchField')}
      variant="outlined"
      value={search}
      onChange={onSearchChange}
    />
  );
};

export const ContentFilters = ({
  isLoading,
  pregnancy,
  setAgeLevel,
  ageLevelsList,
  selectedAgeLevel,
  categoriesList,
  selectedCategory,
  setCategory,
  displayFilters,
  setDisplayFilters,
}: {
  isLoading: boolean;
  pregnancy: boolean;
  setAgeLevel: (ageLevel?: number) => void;
  ageLevelsList: AgeLevel[];
  selectedAgeLevel: number;
  categoriesList: Categories[];
  selectedCategory: number;
  setCategory: (category?: number) => void;
  displayFilters: boolean;
  setDisplayFilters: (display: boolean) => void;
}) => {
  if (isLoading) {
    return null;
  }
  return (
    <Box sx={{ paddingLeft: '1rem' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            setDisplayFilters(!displayFilters);
          }}
          color={displayFilters ? 'primary' : 'default'}
        >
          <Tune fontSize="small" />
        </IconButton>
        <PregnancyFilter
          pregnancy={pregnancy}
          setSelectedAgeLevel={setAgeLevel}
        />
      </Box>
      {!pregnancy && displayFilters && (
        <AgeLevelsList
          list={ageLevelsList}
          selected={selectedAgeLevel}
          setSelected={setAgeLevel}
        />
      )}
      {displayFilters && (
        <CategoriesList
          list={categoriesList}
          selected={selectedCategory}
          setSelected={setCategory}
        />
      )}
    </Box>
  );
};
