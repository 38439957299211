import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useLocale } from 'react-admin';
import { useDispatch } from 'react-redux';

import { AppointmentSuggestion } from '@boTypes/appointmentSuggestion';
import { DailyTip } from '@boTypes/dailyTip';
import { DiscussionEventType, LookupDTO } from '@boTypes/discussionEvent';
import { FertiTip } from '@boTypes/fertiTip';
import Folder from '@boTypes/folder';
import { Guide } from '@boTypes/guide';
import ItemSuggestion from '@boTypes/ItemSuggestion';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import PregnancyQuestion from '@boTypes/PregnancyQuestion';
import { PregnancyWeekContent } from '@boTypes/pregnancyWeekContent';
import { TodoListSuggestion } from '@boTypes/todoListSuggestion';
import { WeeklyTip } from '@boTypes/weeklyTip';
import { useQuery } from '@hooks/queryWrappers';
import { useDebouncedCallback } from '@hooks/useDebouncedCallback';
import { SelectChangeEvent } from '@mui/material';

import { ContentType } from './contentTextList';
import { libraryMode } from './layout';
import {
  logLibraryContentTypeSelected,
  logLibraryFilter,
  logLibraryModeSelect,
  logLibrarySearch,
} from '../../analytics/events';
import { LibraryContext } from '../../common/context';
import {
  useAgeLevelsList,
  useCategoriesList,
  useOneKindList,
  useMultiSearch,
  CMSSearchResults,
} from '../../hooks/cms';
import { useSelector } from '../../store';
import { setReduxSearch } from '../../store/librarySearch';
import { useSearchKey } from '../../utils/search';

export const useSearchText = () => {
  const initialSearch = useSelector(
    (state) => state.librarySearch,
    () => true, // only run on first render
  );
  const [displayedText, setDisplayedText] = useState(initialSearch ?? '');
  const [searchedText, setSearchedText] = useState(initialSearch ?? '');
  const dispatch = useDispatch();

  const debouncedSetSearchText = useDebouncedCallback((text) => {
    setSearchedText(text);
    dispatch(setReduxSearch({ search: text }));
  }, 300);

  const setSearchText = useCallback(
    (text: string) => {
      setDisplayedText(text);
      dispatch(setReduxSearch({ search: '' }));
      if (text) {
        debouncedSetSearchText(text);
      } else {
        debouncedSetSearchText.cancel();
        setSearchedText('');
      }
    },
    [debouncedSetSearchText, dispatch],
  );

  const resetSearch = useCallback(() => {
    debouncedSetSearchText.cancel();
    setDisplayedText('');
    setSearchedText('');
    dispatch(setReduxSearch({ search: '' }));
  }, [debouncedSetSearchText, dispatch]);

  return {
    displayedText,
    searchedText,
    setSearchText,
    resetSearch,
  };
};

/**
 * Returns the pregnancy filter state from the redux store. It is a 3 state value :
 *  - null if no filter is applied
 *  - true if the filter is applied on pregnancy content
 *  - false if the filter is applied on children content
 * @returns The pregnancy filter state.
 */
export const usePregnancyFilter = () => {
  const pregnancy = useSelector((state) => state.libraryFilters);
  return [
    pregnancy,
    pregnancy === null ? null : pregnancy === LibraryContext.PREGNANCY,
  ] as [LibraryContext | null, boolean | null];
};

export const useLibrary = (
  selectedContent: ContentType | null,
  mode: libraryMode,
) => {
  const originalLocale = useLocale();
  const [locale, setLocale] = useState(originalLocale);
  const [selectedAgeLevel, _setAgeLevel] = useState<number>();
  const [selectedCategory, _setCategory] = useState<number>();
  const [displayFilters, setDisplayFilters] = useState(false);

  const [_pregnancy, pregnancy] = usePregnancyFilter();

  const ageLevelRef = useRef(selectedAgeLevel);
  const categoryRef = useRef(selectedCategory);
  ageLevelRef.current = selectedAgeLevel;
  categoryRef.current = selectedCategory;
  const _pregnancyRef = useRef(_pregnancy);

  const { displayedText, searchedText, setSearchText, resetSearch } =
    useSearchText();

  const setAgeLevel = (ageLevel: number) => {
    logLibraryFilter({ ageLevel: ageLevel, category: selectedCategory });
    _setAgeLevel(ageLevel);
  };

  const setCategory = (category: number) => {
    logLibraryFilter({ ageLevel: selectedAgeLevel, category: category });
    _setCategory(category);
  };

  useEffect(() => {
    if (searchedText) {
      logLibrarySearch(
        searchedText,
        ageLevelRef.current,
        categoryRef.current,
        _pregnancyRef.current,
      );
    }
  }, [searchedText]);

  const searchKey = useSearchKey();
  const {
    data: items,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isPending: isOneKindListPending,
  } = useOneKindList(selectedContent, {
    ageLevel: selectedAgeLevel,
    category: selectedCategory,
    pregnancy,
    search: searchedText,
    searchKey,
    enabled: mode === libraryMode.EXPLORER,
    locale,
  });

  const { data: multiSearch, isPending: isMultiSearchPending } = useMultiSearch(
    {
      search: searchedText,
      searchKey,
      ageLevel: selectedAgeLevel,
      category: selectedCategory,
      pregnancy,
      enabled: mode === libraryMode.SEARCH && Boolean(searchedText),
      locale,
    },
  );

  // search agnostic data
  const { data: ageLevelsList, isLoading: isAgeLevelsLoading } =
    useAgeLevelsList();
  const { data: categoriesList, isLoading: isCategoriesLoading } =
    useCategoriesList(pregnancy);

  const isLoading =
    (mode === libraryMode.SEARCH &&
      isMultiSearchPending &&
      Boolean(searchedText)) ||
    (mode === libraryMode.EXPLORER && isOneKindListPending) ||
    displayedText !== searchedText;

  const isLoadingFilters = isAgeLevelsLoading || isCategoriesLoading;

  return {
    ageLevelsList,
    categoriesList,
    displayFilters,
    displayedText,
    explorer: {
      items,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
    },
    isLoading,
    isLoadingFilters,
    locale,
    pregnancy,
    resetSearch,
    searchedText,
    searchResults: {
      ...multiSearch,
    },
    selectedAgeLevel,
    selectedCategory,
    setAgeLevel,
    setCategory,
    setDisplayFilters,
    setLocale,
    setSearchText,
  };
};

export const useSentContent = (discussionId: number, open?: boolean) => {
  const { data = [], refetch } = useQuery<LookupDTO[]>(
    ['lookups', discussionId],
    () => ({
      url: `/api/discussion_events/lookups/${discussionId}`,
      method: 'get',
    }),
    {
      enabled: Boolean(discussionId),
    },
  );

  // Used to refetch data when the modal is opened with the suggested content usecase
  useEffect(() => {
    open && refetch();
  }, [open, refetch]);

  return data;
};

export const contentTypeToDiscussionEventType = (contentType: ContentType) =>
  ({
    [ContentType.GUIDES]: DiscussionEventType.GUIDE,
    [ContentType.POSTS]: DiscussionEventType.POST,
    [ContentType.MASTERCLASSES]: DiscussionEventType.MASTERCLASS,
    [ContentType.FOLDER]: DiscussionEventType.FOLDER,
    [ContentType.DAILY_TIPS]: DiscussionEventType.DAILY_TIP,
    [ContentType.WEEKLY_TIPS]: DiscussionEventType.WEEKLY_TIP,
  })[contentType];

export const useResultsWithSeen = (
  searchResults: CMSSearchResults,
  sentContent: Record<DiscussionEventType, LookupDTO[]>,
) => {
  return {
    macroList: searchResults?.macros ?? [],
    guideList:
      searchResults?.guides?.map((guide) => ({
        ...guide,
        sent: Boolean(
          sentContent[DiscussionEventType.GUIDE]?.find(
            (el) => el.content === guide.slug,
          ),
        ),
      })) ?? [],
    postList:
      searchResults?.posts?.map((post) => ({
        ...post,
        sent: Boolean(
          sentContent[DiscussionEventType.POST]?.find(
            (el) => el.content === post.slug,
          ),
        ),
      })) ?? [],
    masterclassList:
      searchResults?.masterclasses?.map((masterclass) => ({
        ...masterclass,
        sent: Boolean(
          sentContent[DiscussionEventType.MASTERCLASS]?.find(
            (el) => el.content === String(masterclass.id),
          ),
        ),
      })) ?? [],
    folderList:
      searchResults?.folders?.map((folder) => ({
        ...folder,
        sent: Boolean(
          sentContent[DiscussionEventType.FOLDER]?.find(
            (el) => el.content === String(folder.id),
          ),
        ),
      })) ?? [],
    dailyTipList:
      searchResults?.['daily-tips']?.map((dailyTip) => ({
        ...dailyTip,
        sent: Boolean(
          sentContent[DiscussionEventType.DAILY_TIP]?.find(
            (el) => el.content === String(dailyTip.id),
          ),
        ),
      })) ?? [],
    weeklyTipList:
      searchResults?.['weekly-tips']?.map((weeklyTip) => ({
        ...weeklyTip,
        sent: Boolean(
          sentContent[DiscussionEventType.WEEKLY_TIP]?.find(
            (el) => el.content === String(weeklyTip.id),
          ),
        ),
      })) ?? [],
  };
};

export const useItemsWithSeen = (
  items: (
    | AppointmentSuggestion
    | DailyTip
    | FertiTip
    | Folder
    | Guide
    | ItemSuggestion
    | Macro
    | Macro
    | Masterclass
    | Post
    | PregnancyQuestion
    | PregnancyWeekContent
    | TodoListSuggestion
    | WeeklyTip
  )[],
  sentContent?: LookupDTO[],
) => {
  return (
    items?.map((item) => ({
      ...item,
      sent: Boolean(
        sentContent?.find((el) => {
          if (
            [DiscussionEventType.GUIDE, DiscussionEventType.POST].includes(
              el.type,
            )
          ) {
            return el.content === (item as Guide | Post).slug;
          } else {
            return el.content === String(item.id);
          }
        }),
      ),
    })) ?? []
  );
};

export const useLibraryMode = (
  setContentType: React.Dispatch<React.SetStateAction<ContentType | null>>,
) => {
  const [mode, setMode] = useState<libraryMode>(libraryMode.SEARCH);
  const onModeChange = useCallback(
    (event: SelectChangeEvent) => {
      const _mode = event.target.value as libraryMode;
      logLibraryModeSelect(_mode);
      setMode(_mode);
      setContentType(_mode === libraryMode.SEARCH ? null : ContentType.MACRO);
    },
    [setMode, setContentType],
  );

  return { mode, onModeChange };
};

export const useContentType = () => {
  const [selectedContentType, setContentType] = useState<ContentType | null>();
  const onTypeChange = (event: SelectChangeEvent) => {
    const contentType = event.target.value as ContentType;
    logLibraryContentTypeSelected(contentType);
    setContentType(contentType);
  };

  return { selectedContentType, onTypeChange, setContentType };
};
