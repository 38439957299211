import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import { useHandleLongPress } from '@hooks/useHandleLongPress';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  Avatar,
  Box,
  CardActions,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { logContentView } from '../../analytics/events';
import { DailyTip } from '../../types';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;
const TITLE_LINE_NB = 5;

const InnerDailyTipContent = ({ item }: { item: DailyTip }) => {
  const author = item?.authorName ? (
    <Stack
      direction="row"
      gap={1}
      justifyContent={'flex-start'}
      alignContent={'center'}
    >
      {item?.authorImage && (
        <Avatar
          src={item.authorImage}
          alt={item?.authorName}
          sx={{
            width: 24,
            height: 24,
            borderWidth: 1,
            borderColor: 'background.grey',
          }}
        />
      )}
      <Typography variant="body2">{item.authorName}</Typography>
    </Stack>
  ) : null;

  return (
    <>
      {author}
      <Box
        sx={{
          maxWidth: '100%',
          '& p > img': { width: '100%' },
          '& p': { wordWrap: 'break-word' },
        }}
      >
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </Box>
    </>
  );
};

export const DailyTipItemDialog = ({
  item,
  translatedItem,
  open,
  close,
}: {
  item: DailyTip;
  translatedItem?: DailyTip;
  open: boolean;
  close: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth={translatedItem ? 'xl' : 'md'}
    >
      <DialogTitle
        onClose={close}
        sx={{
          fontFamily: 'Lora, serif',
          fontWeight: 600,
          color: 'primary.main',
        }}
      >
        {translatedItem ? (
          <Stack direction="row" gap={6} sx={{ width: '100%' }}>
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              {item.title}
            </Stack>
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              {translatedItem.title}
            </Stack>
          </Stack>
        ) : (
          item.title
        )}
      </DialogTitle>
      <DialogContent>
        {translatedItem ? (
          <Stack
            direction="row"
            justifyContent={'space-between'}
            gap={1}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ width: '100%' }}
          >
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              <InnerDailyTipContent item={item} />
            </Stack>
            <Stack direction="column" sx={{ flex: 1, maxWidth: '50%' }}>
              <InnerDailyTipContent item={translatedItem} />
            </Stack>
          </Stack>
        ) : (
          <InnerDailyTipContent item={item} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const DailyTipItem = ({
  item,
  onSend,
  onLongPress,
}: {
  item: DailyTip;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
  ) => void;
  onLongPress?: () => void;
}) => {
  const { title } = item;
  const [open, setOpen] = React.useState(false);
  const onClick = useCallback(() => {
    logContentView('dailyTip', item.id);
    setOpen(true);
  }, [setOpen, item.id]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const dailyTipInfo = {
    type: DiscussionEventType.DAILY_TIP,
    content: String(item.entryId || item.id),
  };
  const [handlePress, startClick] = useHandleLongPress(onClick, onLongPress);
  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      elevation={0}
      sx={{
        backgroundColor: 'warning.main',
      }}
    >
      {open ? (
        <DailyTipItemDialog item={item} open={open} close={close} />
      ) : null}

      <CMSCardActionArea
        onClick={handlePress}
        onMouseDown={startClick}
        height={HEIGHT}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <Box
          sx={{
            position: 'absolute',
            bottom: -0.15 * WIDTH,
            left: -0.2 * WIDTH,
            width: 0.7 * WIDTH,
            height: 0.7 * WIDTH,
            borderRadius: '50%',
            backgroundColor: 'warning.main75',
          }}
        />
        <CMSCardContent
          paddingVertical={0}
          sx={{
            zIndex: 3,
          }}
        >
          <CMSCardTitle
            variant="body2"
            component="h2"
            clamp={TITLE_LINE_NB}
            sx={{
              zIndex: 20,
              fontFamily: 'Lora, serif',
              fontWeight: 600,
              height: '100%',
              textShadow: '0px 0px 4px rgba(255, 255, 255, 0.8)',
            }}
          >
            {title}
          </CMSCardTitle>
        </CMSCardContent>
        {item.sent && (
          <DownloadDoneIcon
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              bottom: theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() =>
              onSend(dailyTipInfo, DiscussionEventType.DAILY_TIP, item.id)
            }
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
